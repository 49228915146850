import { FC, HTMLAttributes, PropsWithChildren } from 'react'

import { getClassNames } from '../../utils'
import styles from './style.module.scss'

type PageProps = PropsWithChildren & HTMLAttributes<HTMLDivElement>

export const Page: FC<PageProps> = ({
  children,
  className,
  ...rest
}: PageProps): JSX.Element => (
  <div className={getClassNames(styles.Page, className)} {...rest}>
    {children}
  </div>
)
