import { FC } from 'react'

import { ModalWrapper } from '../../components'
import { getClassNames, initScripts } from '../../utils'
import styles from './style.module.scss'

export const PermissionsModal: FC = (): JSX.Element => {
  const getMediaDevices = async (): Promise<void> => {
    window.dispatchEvent(new CustomEvent('modal-close'))
    initScripts()
  }

  return (
    <ModalWrapper>
      <div className={getClassNames(styles.Content)}>
        <header className={getClassNames(styles.Header)}>
          Разрешите доступ к камере
        </header>
        <p>Чтобы записать видео-кружок с одной из масок</p>
        <button
          className={getClassNames(styles.Button)}
          onClick={getMediaDevices}
        >
          Разрешить
        </button>
      </div>
    </ModalWrapper>
  )
}
