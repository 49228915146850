import { FC, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { ErrorModal, PermissionsModal, PreviewVideoModal } from './modals'
import { MainPage } from './pages'

export const App: FC = (): JSX.Element => {
  const location = useLocation()
  const state = location.state as { popup?: Location }
  const navigate = useNavigate()
  useEffect(() => {
    if (!window.Telegram || !window.Telegram.WebApp.initData.length) {
      window.open('https://t.me/AstroLine_bot')
      // window.close()
    }
    getPermissionsScreen()
    window.Telegram.WebApp.expand()
  }, [])

  const getPermissionsScreen = (): void => {
    navigate('/permissions', {
      state: {
        popup: location
      },
      replace: true
    })
  }

  return (
    <>
      {location.state && (
        <Routes>
          <Route path="/preview-video" element={<PreviewVideoModal />}></Route>
          <Route path="/permissions" element={<PermissionsModal />}></Route>
          <Route path="/error" element={<ErrorModal />}></Route>
        </Routes>
      )}
      <Routes location={state?.popup || location}>
        <Route path="*" element={<MainPage />} />
      </Routes>
    </>
  )
}
