import { MaskType } from '../components/camera/camera.types'
import { eventbus } from '../utils/event-bus.utils'

export const MaskEventChannel = eventbus<{
  mask_onload: () => void
  mask_start_video: (mask: MaskType) => void
  mask_stop_video: (duration: number) => void
  mask_stop_video_timer: () => void
  mask_reset_video: () => void
  mask_send_video: () => void
  mask_change: (mask: MaskType) => void
}>()
