import { FC } from 'react'

import { ModalWrapper } from '../../components'
import { getClassNames } from '../../utils'
import styles from './style.module.scss'

export const ErrorModal: FC = (): JSX.Element => {
  const reloadPage = async (): Promise<void> => {
    window.Telegram.WebApp.close()
  }

  return (
    <ModalWrapper>
      <div className={getClassNames(styles.Content)}>
        <header className={getClassNames(styles.Header)}>
          Произошла ошибка
        </header>
        <p>
          Проверьте что ваша камера подключена и не используется другими
          приложениями
        </p>
        <button className={getClassNames(styles.Button)} onClick={reloadPage}>
          Перезапустить
        </button>
      </div>
    </ModalWrapper>
  )
}
