import { FC, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { MaskEventChannel } from '../../event-channels'
import { getClassNames } from '../../utils'
import { SpinnerIcon } from '../spinner'
import { CameraElement, CameraState, MaskType } from './camera.types'
import styles from './style.module.scss'

export const Camera: FC = (): JSX.Element => {
  const duration = useRef<number>(0)
  const [cameraState, setCameraState] = useState<CameraState>('loading')
  const { on, emit } = MaskEventChannel
  const ref = useRef<CameraElement>(null)
  const progressRef = useRef<SVGCircleElement>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const handleCameraError = (): void => {
    setCameraState('error')
    window.Telegram.WebApp.HapticFeedback.notificationOccurred('error')
    navigate('/error', {
      state: {
        popup: location
      }
    })
  }

  const handleCameraLoaded = (): void => {
    window.Telegram.WebApp.HapticFeedback.notificationOccurred('success')
    setCameraState('loaded')
    window.dispatchEvent(new CustomEvent('camera_loaded'))
    ref.current?.playAnimation('planets', true)
  }

  const handleVideoElementRendered = (): void => {
    const videoElement = document.getElementsByTagName('video')[0]
    videoElement.muted = true
    videoElement.playsInline = true
    videoElement.autoplay = true
    videoElement.play()
  }

  const handleRecordedVideoLoaded = async (): Promise<void> => {
    duration.current = ref.current?.videoDuration || 0
    navigate('/preview-video', {
      state: {
        videoUrl: ref.current!.videoDownloadUrl!,
        popup: location,
        duration: duration.current
      }
    })
  }

  useEffect(() => {
    let videoStopTimer: number = 0
    let videoTimerInterval: number = 0
    let localDuration = 0
    if (ref.current) {
      ref.current.addEventListener('camera_notfound', handleCameraError)
      ref.current.addEventListener('camera_notallowed', handleCameraError)
      ref.current.addEventListener('camera_loaded', handleCameraLoaded)
      ref.current.addEventListener(
        'camera_videorecord_loaded',
        handleRecordedVideoLoaded
      )
      ref.current.addEventListener(
        'videoelement_rendered',
        handleVideoElementRendered
      )
    }
    on('mask_change', (mask: MaskType): void => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium')
      ref.current?.setMask(mask)
      if (mask === 'suit') {
        ref.current?.playAnimation('planets', true)
      }
    })
    on('mask_onload', (): void => {
      ref.current?.init()
    })
    on('mask_start_video', (mask: MaskType): void => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light')
      if (mask == 'suit') {
        ref.current?.playAnimation('helmet', false, 2000)
      }
      ref.current?.startRecording()
      localDuration = Date.now()
      videoStopTimer = setTimeout(() => {
        emit('mask_stop_video_timer')
      }, 60000)
      videoTimerInterval = setInterval(() => {
        updateProgressBar(localDuration - Date.now())
      }, 10)
    })
    on('mask_stop_video', (): void => {
      clearTimeout(videoStopTimer)
      clearInterval(videoTimerInterval)
      localDuration = 0
      updateProgressBar(0)
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light')
      ref.current?.stopRecording()
    })
  }, [])

  const updateProgressBar = (duration: number): void => {
    const percentageComplete = duration / 60000
    const strokeDashOffsetValue =
      100 - (percentageComplete > 1 ? 1 : percentageComplete) * 100
    if (progressRef.current)
      progressRef.current.style.strokeDashoffset =
        strokeDashOffsetValue.toString()
  }

  return (
    <>
      <div
        id="container"
        className={getClassNames(
          styles.Camera,
          styles['Camera--' + cameraState]
        )}
        ref={ref}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          className={getClassNames(styles.progress)}
        >
          <circle
            cx="16"
            cy="16"
            r="15.9155"
            className={getClassNames(styles['progress-bar__background'])}
          />

          <circle
            cx="16"
            cy="16"
            r="15.9155"
            ref={progressRef}
            className={getClassNames(styles['progress-bar__progress'])}
          />
        </svg>
        <div className={getClassNames(styles.Loader)}>
          <SpinnerIcon />
        </div>
      </div>
    </>
  )
}
