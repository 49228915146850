import { FC } from 'react'

import { getClassNames } from '../../utils'
import styles from './BackgroundStar.module.scss'

export const BackgroundStar: FC = () => {
  return (
    <svg
      width="428"
      height="478"
      viewBox="0 0 428 478"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={getClassNames(styles.BgStar)}
    >
      <path
        d="M268.9 332.9L151.7 363.1L210.3 451L268.9 332.9Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-7.20001 202L107.6 250.9L13.4 269.2L-7.20001 202Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M338.6 436.6L248.7 373.1L210.3 451L338.6 436.6Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M268.9 332.9L105.3 301.6L151.7 363.1L268.9 332.9Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 342.7L80.1 342.9L210.3 451C210.3 451 67.5 449.7 67.8 448.7C68.1 447.7 80 342.7 80 342.7Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.60001 399.7L80 342.7L67.8 448.7L7.60001 399.7Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-108.9 281.2L29.7 336.2L-60.5 365.8L-108.9 281.2Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4 138.2L29.7 336.2L-54 303L22.4 138.2Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-128.9 197L22.4 138.2L-137.5 250.9L-128.9 197Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.3 301.6L107.6 250.9L80 342.7L29.7 336.2L80 342.7L105.3 301.6Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.4 22.7998L87.9 87.5998L-25.3 115L-37.1 10.6998L39.9 41.9998L-25.3 115L87.9 87.5998L238.6 93.2998L116.2 21.5998L-25.3 115L22.4 138.2L-102.9 45.8998L-34 38.0998L-99.7 3.7998L-37.1 10.6998"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M377.2 0.5L381 109.3L507.1 38L463.7 20.3L381 109.3L423.1 10.7"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M381 109.3L290 20.3002L258.4 10.7002L238.7 93.4002L88 87.6002L52.8 63.6002C52.8 63.6002 41.3 44.7002 40 42.1002L116.3 21.7002L258.5 10.8002"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4 269.2L29.7 336.2"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4 138.2L87.9 87.6L390.8 157.5L381 109.3L255 24.6L289.9 20.3L377.2 0.5L417.9 10.7L463.7 20.3"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M238.6 93.3002L390.8 157.5L258 26.7002"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M432 323.5L390.8 157.5L268.9 332.9L338.6 436.6L432 323.5ZM432 323.5L414.5 477L385.4 469.1L432 323.5ZM432 323.5L514.4 469.1L561.7 445.3L557.1 328.3"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M557.1 328.3L512.8 161.7L381 109.3"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 342.7L-57.5 365.8L7.60001 399.7L67.8 448.7L-7.2 477L7.60001 399.7"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M514.4 469.1L414.5 477"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-60.5 365.8L-7.2 477L210.3 451L385.4 469.1L338.6 436.6L390.8 157.5L512.8 161.7L507.1 38"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
