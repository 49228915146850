import { FC } from 'react'

import { getClassNames } from '../../utils'
import styles from './style.module.scss'

type SpinnerIconProps = {
  className?: string
}

export const SpinnerIcon: FC<SpinnerIconProps> = ({
  className
}): JSX.Element => (
  <svg
    className={getClassNames(
      className,
      styles.spinner,
      className && styles.static
    )}
    viewBox="0 0 50 50"
  >
    <circle
      className={getClassNames(styles.path)}
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    />
  </svg>
)
