import { FC } from 'react'

import { getClassNames } from '../../utils'
import styles from './style.module.scss'

export const Header: FC = (): JSX.Element => (
  <header className={getClassNames(styles.Header)}>
    <a href="https://winline.ru/" target="_blank" rel="noreferrer">
      <img alt="WinLine Logo" src="/win_logo.png" />
    </a>
  </header>
)
