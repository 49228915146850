import {
  Icon24Mute,
  Icon24Pause,
  Icon24Play,
  Icon24Volume
} from '@vkontakte/icons'
import ysFixWebmDuration from 'fix-webm-duration'
import { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ModalWrapper, SpinnerIcon } from '../../components'
import { getClassNames } from '../../utils'
import styles from './style.module.scss'
import * as Sentry from '@sentry/react'

export const PreviewVideoModal: FC = (): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [video, setVideo] = useState<{
    url: string
    blob: Blob | undefined
  }>({
    url: '',
    blob: undefined
  })
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoProps, setVideoProps] = useState<{
    muted: boolean
    paused: boolean
  }>({
    paused: true,
    muted: true
  })
  const { state } = useLocation() as {
    state: {
      videoUrl: string
      duration: number
    }
  }
  useEffect(() => {
    normalizeDuration()
    if (videoRef.current) {
      setVideoProps({
        muted: videoRef.current!.muted,
        paused: videoRef.current!.paused
      })
    }
  }, [])

  useEffect(() => {
    if (video.url.length > 0 && typeof video.blob !== 'undefined') {
      if (videoRef.current) {
        const videoElement = videoRef.current
        videoElement.playsInline = true
        videoElement.src = video.url
      }
    }
  }, [video])

  const normalizeDuration = async (): Promise<void> => {
    const response_video = await fetch(state.videoUrl)
    const video_blob = await response_video.blob()
    const blob = await ysFixWebmDuration(video_blob, state.duration)
    const url = URL.createObjectURL(blob)
    setVideo({
      url,
      blob
    })
  }

  const sendVideoNote = async (): Promise<void> => {
    const formData = new FormData()
    formData.append('name', `video.mp4`)
    formData.append('blob', video.blob!, 'video.mp4')
    formData.append('duration', state.duration.toString())
    setLoading(true)
    fetch('https://tg-mask-backend.way2ar.com/upload_video', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'TG ' + window.Telegram.WebApp.initData
      }
    })
      .then(async (response: Response) => {
        const { ok, eta, position } = (await response.json()) as {
          ok: boolean
          eta: string
          position: number
        }
        if (!ok) throw new Error('Error from backend')
        window.Telegram.WebApp.HapticFeedback.notificationOccurred('success')
        window.Telegram.WebApp.showAlert(
          position === 0
            ? `Видео отправлено в личные сообщения`
            : `Вы добавлены в очередь на отправку видео, ваша позиция в очереди №${position}, примерное время ожидания ${eta}`
        )
        exit()
      })
      .catch((err) => {
        setLoading(false)
        Sentry.captureException(err)
        console.error(err)
        window.Telegram.WebApp.HapticFeedback.notificationOccurred('error')
        window.Telegram.WebApp.showPopup({
          title: 'Произошла ошибка',
          message:
            'При загрузке видео произошла ошибка, вы можете попробовать отправить видео снова, либо перезаписать его'
        })
      })
  }

  const exit = (): void => {
    window.dispatchEvent(new CustomEvent('modal-close'))
  }

  const onPlayClick = (event: MouseEvent): void => {
    event.stopPropagation()
    if (videoRef.current) {
      if (videoRef.current.paused) videoRef.current.play()
      else videoRef.current.pause()
      setVideoProps((prevState) => {
        return { ...prevState, paused: videoRef.current!.paused }
      })
    }
  }

  const onVolumeClick = (event: MouseEvent): void => {
    event.stopPropagation()
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted
      if (videoRef.current.paused && !videoRef.current.muted) onPlayClick(event)
      setVideoProps((prevState) => {
        return { ...prevState, muted: videoRef.current!.muted }
      })
    }
  }

  return (
    <ModalWrapper>
      <div className={getClassNames(styles.Content)}>
        <header className={getClassNames(styles.Header)}>
          Что делаем с видео?
        </header>
        <div className={getClassNames(styles.Container)} onClick={onPlayClick}>
          <video
            className={getClassNames(styles.Video)}
            ref={videoRef}
            src={video.url}
            muted={videoProps.muted}
            preload="auto"
            playsInline
            loop
          />
          <p className={getClassNames(styles.Text)}>Нажми для запуска видео!</p>
          <div className={getClassNames(styles.Controllers)}>
            <div
              onClick={onPlayClick}
              className={getClassNames(styles.Controller)}
            >
              {videoProps.paused ? (
                <Icon24Play fill="white" />
              ) : (
                <Icon24Pause fill="white" />
              )}
            </div>
            <div
              onClick={onVolumeClick}
              className={getClassNames(styles.Controller)}
            >
              {!videoProps.muted ? (
                <Icon24Volume fill="white" />
              ) : (
                <Icon24Mute fill="white" />
              )}
            </div>
          </div>
        </div>
        <div className={getClassNames(styles.Buttons)}>
          <SpinnerIcon
            className={getClassNames(
              styles.Spinner,
              isLoading && styles['Spinner--active']
            )}
          />
          <button
            className={getClassNames(styles.Button)}
            onClick={sendVideoNote}
            disabled={isLoading}
          >
            Отправить
          </button>
          <button
            className={getClassNames(styles.Button)}
            onClick={exit}
            disabled={isLoading}
          >
            Перезаписать
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}
