export type CameraElement = HTMLDivElement & {
  init: () => void
  startRecording: () => void
  stopRecording: () => void
  videoDownloadUrl?: string
  setMask: (type: MaskType) => void
  videoDuration?: number
  playAnimation: (
    planets: 'helmet' | 'planets',
    loop: boolean,
    timeout?: number
  ) => void
}

export type CameraState = 'loaded' | 'loading' | 'error'

export type MaskType = 'kokoshnik' | 'beard' | 'suit'
export const masks: MaskType[] = ['suit']
