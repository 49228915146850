import { MaskEventChannel } from '../event-channels'

export const initScripts = (): void => {
  const { emit } = MaskEventChannel
  const script_ar = document.createElement('script')
  const script_mask = document.createElement('script')

  script_ar.src = '/libs/index.83afb627.js'
  script_ar.type = 'module'
  script_ar.crossOrigin = 'anonymous'

  script_mask.src = '/libs/mindar-face-three.prod.js'

  document.body.appendChild(script_mask)
  document.body.appendChild(script_ar)

  script_mask.onload = () => emit('mask_onload')
}
