import { FC, MouseEvent, PropsWithChildren, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getClassNames } from '../../utils'
import style from './style.module.scss'

export const ModalWrapper: FC<PropsWithChildren> = ({
  children
}): JSX.Element => {
  const [isClosed, setIsClosed] = useState<boolean>(false)
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    window.addEventListener('modal-close', close)
    return () => {
      document.body.style.overflow = 'auto'
      window.removeEventListener('modal-close', () => void 0)
    }
  }, [])

  const navigate = useNavigate()

  const close = (): void => {
    setIsClosed(true)
  }

  const onCloseAnimationEnd = (): void => {
    if (isClosed) navigate('/')
  }

  const eventStopPropagation = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
  }

  return (
    <div
      className={getClassNames(
        style['ModalWrapper'],
        isClosed && style['ModalWrapper--closed']
      )}
      onAnimationEnd={onCloseAnimationEnd}
    >
      <div
        className={getClassNames(style['ModalWrapper__content'])}
        onClick={eventStopPropagation}
      >
        {children}
      </div>
    </div>
  )
}
