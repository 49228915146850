import { FC, useEffect, useState } from 'react'

import { BackgroundStar, Camera, Header, Page } from '../../components'
import { masks, MaskType } from '../../components/camera/camera.types'
import { MaskEventChannel } from '../../event-channels'
import { getClassNames } from '../../utils'
import styles from './style.module.scss'

export const MainPage: FC = (): JSX.Element => {
  const [startVideoTime, setStartVideoTime] = useState<number>(Date.now())
  const [buttonState, setButtonState] = useState<
    'default' | 'active' | 'disabled'
  >('disabled')
  const [maskType, setMaksType] = useState<MaskType>('suit')
  const { emit, on, off } = MaskEventChannel

  useEffect(() => {
    off('mask_stop_video_timer', () => void 0)
    on('mask_stop_video_timer', () => {
      stopVideo()
    })
  }, [startVideoTime])

  useEffect(() => {
    window.addEventListener('camera_loaded', () => {
      setButtonState('default')
    })
  }, [])

  const onMaskChangeHandler = (mask: MaskType): (() => void) => {
    return (): void => {
      setMaksType(mask)
    }
  }

  useEffect(() => {
    emit('mask_change', maskType)
  }, [maskType])

  const onClickHandler = (): void => {
    if (buttonState === 'default') startVideo()
    if (buttonState === 'active') stopVideo()
  }

  const startVideo = (): void => {
    if (buttonState === 'default') {
      emit('mask_start_video', maskType)
      setButtonState('active')
      setStartVideoTime(Date.now())
    } else if (buttonState === 'active') stopVideo()
  }

  const stopVideo = (): void => {
    const duration = Date.now() - startVideoTime
    if (duration < 1000) return
    if (buttonState === 'active') {
      emit('mask_stop_video', duration)
      setButtonState('default')
    }
  }

  const isMobile = window.matchMedia('(pointer: coarse)').matches
  return (
    <Page className={getClassNames(styles['Page--Main'])}>
      <BackgroundStar />
      <Header />
      <Camera />
      <div className={getClassNames(styles.Recording)}>
        <div className={getClassNames(styles.Recording__mask)}>
          {masks.map((mask) => (
            <button
              className={getClassNames(
                styles.Mask__button,
                mask === maskType && styles['Mask__button--active']
              )}
              disabled={buttonState === 'disabled'}
              onClick={onMaskChangeHandler(mask)}
              key={`Button ${mask}`}
            >
              <img
                alt={`Mask ${mask}`}
                src={`/assets/${mask}.png`}
                key={`Image ${mask}`}
              />
            </button>
          ))}
        </div>
        <button
          onTouchStart={startVideo}
          onTouchEnd={stopVideo}
          onClick={!isMobile ? onClickHandler : void 0}
          className={getClassNames(
            styles.Recording__button,
            styles['Recording__button--' + buttonState]
          )}
        />
      </div>
    </Page>
  )
}
